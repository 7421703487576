import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

function BrandNavigation(props) {
    let navigate = useNavigate();

    /* #region  STATES */
    const [localStatus, setLocalStatus] = useState("");
    const [cacheData, setCacheData] = useState("");

    const { allUserInfo } = useSelector((state) => state.userData);
    const [retailBrands, setRetailBrands] = useState([]);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        const status = localStorage.getItem("status")
        setLocalStatus(status)
        getSingleCacheData("MyCache", window.location.origin)
    }, [1]);

    useEffect(() => {
        if (!allUserInfo) return;
        let retails = allUserInfo?.Company?._retailBrands;
        setRetailBrands(retails);
    }, [allUserInfo])

    const MINUTE_MS = 6000;
    useEffect(() => {
        const interval = setInterval(() => {
            getSingleCacheData("MyCache", window.location.origin)
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, []);

    /*useEffect(() => {
      const timer = setTimeout(() => {
        console.log('Header', JSON.parse(localStorage.getItem("__data")));
      }, 1000);
      return () => clearTimeout(timer);
    });*/
    /* #endregion */

    /* #region  METHODS */
    const expandCollapse = (e) => {
        e.preventDefault();
        if (IsFullScreenCurrently()) {
            GoOutFullscreen();
        } else {
            openFullscreen();
        }
    }

    const IsFullScreenCurrently = () => {
        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
            localStorage.setItem('fullscreen', 'full');
            return false;
        } else {
            localStorage.setItem('fullscreen', 'exit');
            return true;
        }

    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    }

    const GoOutFullscreen = () => {
        document.getElementById("expand-button").classList.remove("fa-compress");
        document.getElementById("expand-button").classList.add("fa-expand");
        if (document.exitFullscreen)
            document.exitFullscreen();
        else if (document.mozCancelFullScreen)
            document.mozCancelFullScreen();
        else if (document.webkitExitFullscreen)
            document.webkitExitFullscreen();
        else if (document.msExitFullscreen)
            document.msExitFullscreen();
    }

    //let elem = props.data;
    let elem = document.body;

    const openFullscreen = () => {
        document.getElementById("expand-button").classList.remove("fa-expand");
        document.getElementById("expand-button").classList.add("fa-compress");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    const logOut = (e) => {
        e.preventDefault();
        /**/
        Swal.fire({
            text: 'Are you sure want to logout?',
            icon: 'success',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            background: '#1d2530',
            color: '#fff',
            iconColor: "#0B0",
            width: "500px",
            padding: "10px"
        }).then((result) => {
            if (result.isConfirmed) {
                clearCacheData()
                props.hideHeader()
                localStorage.clear();
                navigate('/');
            } else if (result.isDenied) {
                Swal.close()
            }
        })
    }

    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
        if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            // console.log("ESC");
            document.getElementById("expand-button").classList.add("fa-expand");
            document.getElementById("expand-button").classList.remove("fa-compress");
        }
    }

    const handleBrandData = (brandName, brandID) => {
        if (typeof props.dataReloadfunction != 'undefined') {
            props.dataReloadfunction(true);
        }
        localStorage.setItem("brandID", brandID);
        localStorage.setItem("brandName", brandName);
    }

    const handleRetailBrandChange = (retailer) => {
        if (typeof props.dataReloadfunction !== 'undefined') {
            props.dataReloadfunction(true);
        }

        localStorage.setItem("brandID", retailer.ourID);
        localStorage.setItem("brandName", retailer.name);
        localStorage.setItem("currentRetailBrandName", retailer.name);
        localStorage.setItem("currentRetailBrandID", retailer._id);
    }

    const getSingleCacheData = async (cacheName, url) => {
        if (typeof caches === 'undefined') return false;
        const cacheStorage = await caches.open(cacheName);
        const cachedResponse = await cacheStorage.match(url);
        if (!cachedResponse || !cachedResponse.ok) {
            setCacheData('Fetched failed!')
        }
        return cachedResponse.json().then((item) => {
            setCacheData(item)
        });
    };
    //console.log(cacheData?.Companies[0]?.retailBrands)

    /* #endregion */

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-fixed-top">
                <div className="previosnext">
                    <ul>
                        <li><Link to="#" onClick={(e) => e.preventDefault()}><i className="fa-thin fa-chevron-left"></i></Link></li> &nbsp;
                        <li><Link to="#" onClick={(e) => e.preventDefault()}><i className="fa-thin fa-chevron-right"></i></Link></li>
                    </ul>
                    <li className="nav-item dropdown profileopen active-brand-names">
                        <Link className="nav-link" data-toggle="dropdown" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
                            <div className="profiletext">

                                <span className="branddisplayname"> {(localStorage.getItem("status") === "Completed")
                                    ? "Active Retail Brand"
                                    : (parseInt(localStorage.getItem("status")) === 100)
                                        ? "Enrolled Retail Brand"
                                        : "Enrolling Retail Brand"}
                                    : <span className={(localStorage.getItem("status") === "Completed")
                                        ? "brandbluename"
                                        : "brandgreenname"}>{(localStorage.getItem("brandName") != "undefined")
                                            ? localStorage.getItem("brandName")
                                                ? localStorage.getItem("brandName")
                                                : "-"
                                            : "-"
                                        }
                                        {(typeof cacheData?.Companies !== 'undefined' && typeof cacheData?.Companies[0]?.retailBrands !== 'undefined' && (cacheData?.Companies?.[0]?.retailBrands)?.length > 1)
                                            ? <i className="fa-thin fa-chevron-down" aria-hidden="true"></i>
                                            : ""}
                                    </span>
                                </span>

                            </div>
                        </Link>
                        {(typeof cacheData?.Companies !== 'undefined' && typeof cacheData?.Companies[0]?.retailBrands !== 'undefined' && (cacheData?.Companies[0]?.retailBrands).length > 1)
                            ?
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ "left": "inherit", "right": "0px" }}>
                                {retailBrands?.map((retailer, index) => {
                                    return (<span key={index}>
                                        <Link to="#" onClick={() => handleRetailBrandChange(retailer)} className="dropdown-item" >
                                            <div className="role-define">{retailer.name}</div>
                                        </Link>
                                        <div className="dropdown-divider"></div>
                                    </span>);
                                })}

                                { /* typeof cacheData?.Companies !== 'undefined' && cacheData?.Companies[0]?.retailBrands?.map((dat, key) => {
                                    return (<span key={key}>
                                        <Link to="#" onClick={() => handleBrandData(dat.retailBrandName, dat.retailBrandID)} className="dropdown-item" >
                                            <div className="role-define">{dat.retailBrandName}</div>
                                        </Link>
                                        <div className="dropdown-divider"></div>
                                    </span>);
                                }) */ }

                            </div>
                            :
                            ""
                        }
                    </li>
                </div>

                <div className="centerbtn ">
                    <Link to="/boimages/creator" className={(localStorage.getItem("status") !== "Completed") ? "btn btn-success button-not-active" : "btn btn-success"} onClick={(e) => { e.preventDefault(); (localStorage.getItem("status") == "Completed") ? navigate('/boimages/creator') : navigate('#') }}>New <span className="boldtitleinline">Bo</span>image</Link>
                    <Link to="#" className={(localStorage.getItem("status") !== "Completed") ? "btn btn-success button-not-active" : "btn btn-success button-not-active"} onClick={(e) => e.preventDefault()}>New Retailer</Link>
                    <Link to="#" className={(localStorage.getItem("status") !== "Completed") ? "btn btn-success button-not-active" : "btn btn-success button-not-active"} onClick={(e) => e.preventDefault()}>New Invite</Link>
                </div>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown profileopen">
                        <Link className="nav-link" data-toggle="dropdown" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
                            <div className="profiletext">
                                <span className="username">{localStorage.getItem('name')} <i className="fa-thin fa-chevron-down" aria-hidden="true"></i></span>
                                <span className="userdesignation include-email">{localStorage.getItem('email')}</span>
                                <span className="userdesignation">Account Owner</span>
                            </div>
                        </Link>
                        {/*<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{"left": "inherit", "right": "0px"}}>   
                                <span className="dropdown-item dropdown-header">Users</span>
                                <Link to="#" className="dropdown-item">
                                <div className="adminrole-change">Test</div>  
                                <div className="role-define">Owner</div>                  
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                            <div className="adminrole-change">Test</div>  
                            <div className="role-define">Admin</div>                    
                            </Link>                
                            </div> 
                        */}
                    </li>
                    <li className="nav-item dropdown notificatonbell">
                        <Link className="nav-link" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
                            <i className="fa-thin fa-bell button-not-active"></i>
                        </Link>
                    </li>
                    <li className="nav-item" id="">
                        <Link className="nav-link btn btn-success button-not-active" data-widget="search" to="#" onClick={(e) => e.preventDefault()} role="button">
                            <i className="fa-thin fa-magnifying-glass"></i>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link id="" className="nav-link btn btn-success" to="#" onClick={expandCollapse} role="button">
                            <i className="fa-thin fa-expand" id="expand-button"></i>
                        </Link>
                    </li>
                    <li className="nav-item logoutbtn">
                        <Link className="nav-link btn btn-success" data-widget="logout" to="#" onClick={logOut} role="button">
                            <i className="fa-thin fa-power-off"></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default BrandNavigation;