import { Navigate } from "react-router-dom";
export default function PublicRoute({ children }) {
    const auth = localStorage.getItem('token');
    const status = localStorage.getItem('status');

    return auth
        ? <Navigate to={(status === "Completed" || parseInt(status) === 100)
            ? "/account/company/list"
            : "/account/company/create"} />
        : children;
}