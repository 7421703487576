import Steps from './steps'

export default function Second(props){

    return (
        <div>
           <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections">
			            	<h3>Create a BOstageR account</h3>
			            				            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="stepsimg">
			            					<i className="fa-light fa-user"></i>
			            					<h3>1</h3>
			            					<h4>Create an account</h4>
			            				</div>
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>Create an account in BOstageR, the platform for Retailers to enroll their stores and affiliate them to brands they work with, as direct fulfillment centers.
			            					</p>
											<p className="lilsmalltext">Right after you finish checking this short guide, you'll land on the
											<span className="whiteandbg"> pre-enrollment form</span> to start the enrollment process. You can go directly to the pre-enrollment form by pressing the "SKIP DETAILS" button.
											</p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	<Steps step={2} {...props} />
		            	</div>
		            </div>
	            </div>
            </div>
        </div>
     </div>
    );
};