import Steps from './steps'
import CircleTick from './../../assets/images/circle-tick.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Warning from './../../assets/images/error-icon.png';
import Success from './../../assets/images/icons8-check-64.png';
toast.configure();

export default function LastPage(props) {

	/* #region  METHODS */
	const resendData = () => {
		props.loader(true)
		axios.post(process.env.REACT_APP_API_URL + 'resend-login-detail?code=' + process.env.REACT_APP_API_CODE, { "id": props.userID }).then((res) => {
			if (res.data.status === 200) {
				toast.success(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={Success} alt="" />
				})
			} else {
				toast.error(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={Warning} alt="" />
				})
			}
			props.loader(false)
		})
	}
	/* #endregion */

	return (
		<div className="wizardarea">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="wizardtext twosections lastonesr">
							<div className="wizadwrap">
								<div className="row">
									<div className="col-md-12">
										<div className="customcheckf">
											<img src={CircleTick} alt="" />
											<span className="showcheckstatus">Completed!</span>
										</div>
										<div className="rightparatext">
											<p>You started creating the <span className="whiteandbg">BO</span>stage<span className="stage-orange">R</span> account for <span className="whiteandbg">{props.companyName} </span>.
											</p>

											<p>The email <span className="whiteandbg">{props.email}</span> should now be checked for credentials (it should take a couple of seconds to arrive). On the email, click the 'LOGIN' button and  use credentials to login and continue with the enrollment.
											</p>
											<p className="lilsmalltext">
												Didn't get it? (allow a few seconds)
											</p>
											<div className="btnsetarea">
												<div className="row">
													<div className="col-md-12 firstbtn text-center">
														<button className="btn btn-default btn-block goto-login-page" onClick={resendData}>RESEND EMAIL</button>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<Steps step={6} {...props} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};