import EnrollForm from './../components/preEnroll/index'
import Logo from "./../assets/images/logologin.png";

const imageData = {
  "logo": Logo,
  "footer_logo": Logo
};

const route = [
  /*{ path: '/enrollment/:id', element: <EnrollForm imageData={imageData}/> }*/
  { path: '/enrollment/:id', Component: EnrollForm }
]

export default route;