import { lazy } from "react";
import LoginFunc from './../utils/updateDataFunc';
import Logo from "./../assets/images/logologin.png";
import FooterLogo from "./../assets/images/footer-logologin.png";
const PrivateRoute = lazy(() => import('./../utils/privateRoute'));
const Dashboard = lazy(() => import('./../components/dashboard/dashboard.js'));

const imageData = {
    "logo": Logo,
    "footer_logo": FooterLogo
};

const route = [
    { path: '/dashboard', element: <PrivateRoute><Dashboard loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> }
];

export default route;