import { lazy } from "react";
import Logo from "./../assets/images/logologin.png";
import FooterLogo from "./../assets/images/footer-logologin.png";
import LoginFunc from './../utils/updateDataFunc';
const PrivateRoute = lazy(() => import('./../utils/privateRoute'));
const BrandList = lazy(() => import('./../components/brands/brandList'));
const BrandCreator = lazy(() => import('./../components/brands/creator'));
const BrandLogo = lazy(() => import('./../components/brands/brandLogo'));

const imageData = {
  "logo": Logo,
  "footer_logo": FooterLogo
};

const route = [
  {
    path: '/brand/list',
    element: <PrivateRoute><BrandList loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
  },
  {
    path: '/brand/creator',
    element: <PrivateRoute><BrandCreator loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
  },
  {
    path: '/brand/creator/:brId',
    element: <PrivateRoute><BrandCreator loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
  },
  {
    path: '/brand/logo/:brandID',
    element: <PrivateRoute><BrandLogo loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
  }
]

export default route;