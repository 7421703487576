import React from "react";
import { Link } from "react-router-dom";

const TermsConditions = ({ setShowTermsCondition, isVisible }) => {

	return (
		<>
			<div className="rightnavigation" style={{ "display": isVisible }}>
				<div className="toheadnav">
					<Link to="#" className="btn btn-success downloadlink">Download</Link>
					<Link to="#" onClick={() => setShowTermsCondition('none')} className="closeicons"><i className="fa fa-times" aria-hidden="true"></i></Link>
				</div>
				<h4>BOtag Norge AS Terms of Service</h4>
				<p>Last updated: 01/08/2022</p>
				<p>These <strong>Terms of Service</strong> (herein named <strong>Terms</strong>) rule the use of the free services, software and websites (herein named <strong>BOstages</strong> or generally, <strong>Service</strong>) provided by <strong>BOtag Norge AS</strong> (herein named <strong>E2S</strong>).<br />
					By using <strong>BOstages</strong>, you agree to be bound by these <strong>Terms</strong>.<br />
					If you’re using <strong>BOstages</strong> on behalf of an organization or entity (herein called <strong>Organization</strong>), then you are agreeing to these <strong>Terms</strong> on behalf of that <strong>Organization</strong> and you represent and warrant that you have the authority to bind the <strong>Organization</strong> to these <strong>Terms</strong>. In that case, “<strong>You</strong>”, “<strong>User</strong>” and “<strong>Your</strong>” refers to that <strong>Organization</strong>.<br />
					If later on you become a paid subscriber to <strong>BOstages</strong>, then your use of the <strong>Service</strong> will be ruled by our <strong>BOstages Subscriber Agreement</strong>.<br />
					<strong>E2S</strong> provides the <strong>Service</strong> to <strong>You</strong> as a <strong>User</strong> in accordance with and subject to the following <strong>Terms</strong>.
				</p>

				<h3>1.	The use of BOstages software</h3>
				<p>We provide BOstages, an online or downloadable client set of software, as part of the Service, which contains other specific routines, features, programs, or other types of E2S software such as BOAds, BOstore, BoCart and Bothebot for your use in connection with the service. The use of BOstages software is ruled under these Terms and grant you a limited, nonexclusive, nontransferable, revocable user license. Under such terms this license does not constitute a sale of the BOstages software. Each specific software under the BOstages set may have their own Terms and Conditions made available with the respective software.<br />
					We update BOstages automatically and gradually make it available for a growing number of different operating systems.</p>

				<p>BOstages allows You to:</p>
				<ol>
					<li>Create marketing campaigns as sets of one or more images normally referred as ads;</li>
					<li>Enhance those images to become direct selling tools for each individual featured product using BOstages specific software called BOAds;</li>
					<li>Affiliate retailers to enroll their stores as Your fulfillment centers for featured products using our specific software called BOstore;</li>
					<li>Make those images available in BOstages ads platform called BOstageP to be fetched and published in any online or off-line publisher as ads or any other type of visual content with the purpose off marketing your products.</li>
					<li>Make those images to be displayed and used as direct selling tools on BoCart, a consumers’ app downloadable to devices to perform the purchase path, or BotheBot a social network for shoppers.</li>
				</ol>
				<p>To make use of BOstages software you need to upload your own data to E2S servers.</p>

				<h3>2.	The User Content</h3>
				<p>All data such as text, photographs, images, illustrations, graphics, video, software, audio or other materials submitted, uploaded, posted or displayed by you on or through E2S servers is considered User Content and under your sole responsibility. You retain ownership of your Content and E2S claims no ownership or control over any User Content. </p>
				<p>By uploading User Content onto the Service, and for the exclusive purpose of:</p>
				<ul>
					<li>providing you the Service and associated support;</li>
					<li>analyzing and improving the operation of the Service;</li>
				</ul>
				<p>you are granting E2S a license to use, copy, reproduce, process, adapt, publish, transmit, host and display that User Content.</p>
				<p>We reserve the right to remove User Content on the Service that violates these Terms or that we otherwise reasonably believe may create liability for E2S.</p>

				<h3>3.	The administration and use of the User Content</h3>
				<p>Signing up to BOstages requires one or more email addresses. One of them shall be used as the administrator of the BOstages account. This email address cannot be changed after the account is settled. Removal will result in deleting the BOstages account and all data associated with it will be lost. The administrator email address shall be of your organization domain (ex: <Link to="username@organization.com">username@organization.com</Link>) and we recommend the use of a dedicated non-personal email address within the company domain. By creating the account under such email address, you acknowledge and understand that your organization may restrict or terminate your access to the BOstages account and elect another person within the organization to take over the admin powers of the BOstages account. In such event new credentials may be generated but the email address shall be kept.<br />
					Other users can be added to the BOstages account without administration powers. These users as well as the administrator, will be able to manage User Content.</p>

				<h3>4.	Access to User Content by Third Parties</h3>
				<p>BOstages is a network based service. At some point you’ll be required to enroll retailers under your network and they will affiliate stores to perform as your Fulfillment Centers. This implies sharing User Content with them so the purpose of completing the whole purchase path for end-consumers is granted. By signing-up to the Service you acknowledge and agree to the sharing of your User Content with retailers and their stores under your network. Shall you require extra terms for the use of the User Content BOstages makes available to them it is your exclusive responsibility to settle those terms directly with them.</p>

				<h3>5.	Your obligations</h3>
				<ul>
					<li>You must provide accurate information when you create your E2S account.</li>
					<li>You are responsible for safeguarding the password and for all activities that occur under your account. You should notify E2S immediately if you become aware of any breach of security or unauthorized use of your account.</li>
					<li>You may never use another user’s account without permission.</li>
					<li>You may not disassemble, decompile or reverse engineer the Service or attempt or assist anyone else to do so, unless such restriction is prohibited by law.</li>
					<li>Our Service is not intended for use by persons under the age of 16. By using the Service, you are representing to us that you are over the age of 16.</li>
				</ul>

				<h3>6.	Pilot Customer special invitation</h3>
				<p>We may, prior to launching one or more of our products and/or from time to time, invite you to become a pilot customer. The invitation is addressed by email to the registered person as the admin of the BOstages account to the email address used to create the BOstages account. Special conditions may be offered and all specification and requirements involving the role of a pilot customer are listed on the email. By accepting the invitation, you agree to be bound to those specifications and requirements. All terms herein remain valid.</p>
			</div>
		</>
	);
};
export default TermsConditions;