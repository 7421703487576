import { lazy } from 'react';
import LoginFunc from './../utils/updateDataFunc';
import Logo from './../assets/images/logologin.png';
const PrivateRoute = lazy(() => import('./../utils/privateRoute'));
const StoreList = lazy(() => import('./../components/store/storeList'));
const CreateStore = lazy(() => import('./../components/store/createStore'));
const CreateOpenHours = lazy(() => import('./../components/store/openHours'));
const StoreDelivery = lazy(() => import('./../components/store/delivery'));
const StoreStaffInvites = lazy(() => import('./../components/store/storeStaffInvites'));
const StoreStaffList = lazy(() => import('./../components/store/storeStaffList'));
const InviteStaff = lazy(() => import('./../components/store/inviteStaffMember'));
//const StoreOrdersList = lazy(() => import('../components/store/storeOrderList'));
const StorePOS = lazy(() => import('../components/store/storePOS'));

const imageData = {
    'logo': Logo,
    'footer_logo': Logo
};

const route = [
    { path: '/store', element: <PrivateRoute><StoreList /></PrivateRoute> },
    { path: '/store/list', element: <PrivateRoute><StoreList /></PrivateRoute> },
    { path: '/store/creator', element: <PrivateRoute><CreateStore loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/store/:storeId', element: <PrivateRoute><CreateStore loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/store/open/:id', element: <PrivateRoute><CreateOpenHours loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/store/delivery/:id', element: <PrivateRoute><StoreDelivery loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/store/invite-staff/:storeID', element: <PrivateRoute><InviteStaff loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/store/staff/invites', element: <PrivateRoute><StoreStaffInvites loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/store/staff/list', element: <PrivateRoute><StoreStaffList loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    // { path: '/store/orders', element: <PrivateRoute><StoreOrdersList loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/store/POS/:storeID', element: <PrivateRoute><StorePOS loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> }
];
 
export default route;