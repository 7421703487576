/* #region Imports */
import React, { useState, useRef, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/styles.css';
import './assets/css/checkbox.css';
import AccountRoute from './routes/accountRoutes';
import BrandRoute from './routes/brandRoutes';
import StoreRoute from './routes/storeRoutes';
import BillingRoute from './routes/billingRoutes';
import FFCRoute from './routes/ffcRoutes';
import ProductRoute from './routes/productRoutes';
import OrdersRoute from './routes/ordersRoutes';
import BoImageRoute from './routes/boImageRoutes';
import DashboardRoute from './routes/dashboardRoutes';
import PreEnrollment from './routes/preEnrollment';
import Logo from './assets/images/logologin.png';
import FooterLogo from './assets/images/footer-logologin.png';
import PublicRoute from './utils/publicRoute';
import BrandNavigation from './components/layouts/DashboardHeader';
import BrandAside from './components/layouts/DashboardAside';
import BrandFooter from './components/layouts/DashboardFooter';
/*import Login from './components/authentication/login';*/
import PreLoader from './components/loader/preLoader';
import * as Library from './utils/Library';
import NoInternetMarker from './components/layouts/NoInternetMarker';
import { useDispatch } from 'react-redux';
import { setData } from './utils/redux/userDataSlice';
import LoginFunc from './utils/updateDataFunc';
/* #endregion */

const Login = lazy(() => import('./components/authentication/login'));
const imageData = {
    'logo': Logo,
    'footer_logo': FooterLogo
};

function App() {

    if (['development', 'testing', 'production', 'staging'].includes(process.env.NODE_ENV)) {
        window.BOLibrary = Library;
    }

    /* #region  STATES */
    const ref = useRef();
    const [showMaster, setShowMaster] = useState(false);
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const dispatch = useDispatch();
    //const { userData } = useSelector((state) => state.userData);

    /* #endregion */

    /* #region  METHODS */
    const getUserDataFunction = (id) => {
        Library.makePostRequest('get-user-data', { id: id }, true).then(res => {
            if (res.data.status === 200) {
                setShowMaster(true);
                const resData = {
                    'status': res.data.data?.status,
                    'completePercentage': res.data.data?.completePercentage,
                    'AccountMenu': res.data.data?.AccountMenu,
                    'DashboardMenu': res.data.data?.DashboardMenu,
                    'BOimagesMenu': res.data.data?.BOimagesMenu,
                    'FullfillmentCenterMenu': res.data.data?.FullfillmentCenterMenu,
                    'StoresMenu': res.data.data?.StoresMenu,
                    'RetailersMenu': res.data.data?.RetailersMenu,
                    'ProductsMenu': res.data.data?.ProductsMenu,
                    'OrdersMenu': res.data.data?.OrdersMenu,
                    'BrandsMenu': res.data.data?.BrandsMenu,
                    'PromotersMenu': res.data.data?.PromotersMenu,
                    'companyName': (typeof res.data.data?.Companies != 'undefined') ? res.data.data?.Companies[0]?.companyName : '',
                    'companyID': (typeof res.data.data?.Companies != 'undefined') ? res.data.data?.Companies[0]?.companyID : '',
                    'companyLogo': (res.data.data?.companyDetail?.logoURL) ? res.data.data?.companyDetail?.logoURL : '',
                    'billingPlan': (res.data.data?.companyDetail?.billingPlan) ? res.data.data?.companyDetail?.billingPlan : '',
                    'BillingMenu': res.data.data?.BillingMenu
                };
                if (typeof res.data.data?.Companies != 'undefined') {
                    localStorage.setItem('companyID', res.data.data?.Companies?.[0]?.companyID);
                }

                //redux save userData
                dispatch(setData({ allUserInfo: res.data.data.allUserData, userAppData: res.data.data }));

                localStorage.setItem('__data', JSON.stringify(resData));
                localStorage.setItem('name', res.data.data.firstName);
                localStorage.setItem('status', res.data.data.status);
                localStorage.setItem('isInEnrolled', res.data.data.isInEnrolled);
                localStorage.setItem('brandID', res?.data?.data?.activeBrand?.retailBrandID);
                localStorage.setItem('brandName', res?.data?.data?.activeBrand?.retailBrandName);
                addDataIntoCache('MyCache', window.location.origin, res.data.data);

                if (!localStorage.getItem('currentRetailBrandID') && !localStorage.getItem('currentRetailBrandName')) {
                    if (res.data.data?.allUserData?.Company?._retailBrands?.length) {
                        const currentRetailer = res.data.data?.allUserData?.Company?._retailBrands.find(item => item.ourID === res?.data?.data?.activeBrand?.retailBrandID);

                        if (currentRetailer) {
                            localStorage.setItem('currentRetailBrandID', currentRetailer._id);
                            localStorage.setItem('currentRetailBrandName', currentRetailer.name);
                        }
                    }
                }

                if (!localStorage.getItem('currentCompanyID')) {
                    const currentCompanyID = res.data.data?.companyDetail?._id;
                    const currentCompanyName = res.data.data?.companyDetail?.name !== undefined
                        ? res.data.data?.companyDetail?.name : '-';

                    localStorage.setItem('currentCompanyID', currentCompanyID);
                    localStorage.setItem('currentCompanyName', currentCompanyName);
                }

            } else {
                localStorage.clear();
            }
        });
    };

    const addDataIntoCache = (cacheName, url, response) => {
        const data = new Response(JSON.stringify(response));
        if ('caches' in window) {
            caches.open(cacheName).then((cache) => {
                cache.put(url, data);
            });
        }
    };

    const hideHeader = () => {
        setShowMaster(false);
    };

    const showHeader = () => {
        setShowMaster(true);
    };

    const checkUserLoggedIn = () => {
        const auth = localStorage.getItem('token');
        if (!auth) {
            setShowMaster(false);
            return <Navigate to="/" />;
        }
    };
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        const interval = setInterval(checkUserLoggedIn, 1000);
        return () => clearInterval(interval);
    });

    useEffect(() => {
        if (isLoggedIn) {
            setShowMaster(true);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            const formData = {
                'id': localStorage.getItem('token')
            };
            Library.makePostRequest('get-user-data', formData, true).then(res => {
                if (res.data.status === 200) {
                    const resData = {
                        'status': res.data.data?.status,
                        'completePercentage': res.data.data?.completePercentage,
                        'AccountMenu': res.data.data?.AccountMenu,
                        'DashboardMenu': res.data.data?.DashboardMenu,
                        'BOimagesMenu': res.data.data?.BOimagesMenu,
                        'FullfillmentCenterMenu': res.data.data?.FullfillmentCenterMenu,
                        'StoresMenu': res.data.data?.StoresMenu,
                        'RetailersMenu': res.data.data?.RetailersMenu,
                        'ProductsMenu': res.data.data?.ProductsMenu,
                        'OrdersMenu': res.data.data?.OrdersMenu,
                        'BrandsMenu': res.data.data?.BrandsMenu,
                        'PromotersMenu': res.data.data?.PromotersMenu,
                        'companyName': (typeof res.data.data?.Companies != 'undefined') ? res.data.data?.Companies[0]?.companyName : '',
                        'companyLogo': res.data.data?.companyDetail?.logoURL,
                        'BillingMenu': res.data.data?.BillingMenu
                    };
                    localStorage.setItem('__data', JSON.stringify(resData));
                    localStorage.setItem('groupName', res.data.data?.groupData);
                    /*deleteSpecificCache("MyCache")
                    addDataIntoCache("MyCache", window.location.origin, res.data.data)*/
                    //setCompanyDet(res.data.data);

                    //redux save userData
                    dispatch(setData({ allUserInfo: res.data.data.allUserData, userAppData: res.data.data }));

                    if (!localStorage.getItem('brandID') && !localStorage.getItem('brandName')) {
                        if (res.data.data?.activeBrand) {
                            localStorage.setItem('brandID', res.data.data?.activeBrand?.brandID);
                            localStorage.setItem('brandName', res.data.data?.activeBrand?.brandName);
                        }
                    }

                    if (!localStorage.getItem('currentRetailBrandID') && !localStorage.getItem('currentRetailBrandName')) {
                        if (res.data.data?.allUserData?.Company?._retailBrands?.length) {
                            const currentRetailer = res.data.data?.allUserData?.Company?._retailBrands.find(item => item.ourID === res?.data?.data?.activeBrand?.retailBrandID);
    
                            if (currentRetailer) {
                                localStorage.setItem('currentRetailBrandID', currentRetailer._id);
                                localStorage.setItem('currentRetailBrandName', currentRetailer.name);
                            }
                        }
                    }
    
                    if (!localStorage.getItem('currentCompanyID')) {
                        const currentCompanyID = res.data.data?.companyDetail?._id;
                        const currentCompanyName = res.data.data?.companyDetail?.name !== undefined
                            ? res.data.data?.companyDetail?.name : '-';
    
                        localStorage.setItem('currentCompanyID', currentCompanyID);
                        localStorage.setItem('currentCompanyName', currentCompanyName);
                    }
                }
                else {
                    localStorage.clear();
                }
            }).catch(error => {
                console.log('catch error');
                localStorage.clear();
            });
        }
    }, [isLoggedIn]);
    /* #endregion */

    return (
        <BrowserRouter>
            <div className="wrapper" ref={ref} id="myscreen">
                {(showMaster) ?
                    <>
                        <BrandNavigation hideHeader={hideHeader} />
                        <BrandAside />
                    </> :
                    ''
                }
                <Suspense fallback={<div><PreLoader /></div>}>
                    <NoInternetMarker />
                    <Routes>
                        <Route path="/" element={<PublicRoute><Login showHeader={showHeader} loginFun={LoginFunc} getUserDataFunction={getUserDataFunction} imageData={imageData} /></PublicRoute>} exact={true} />
                        {PreEnrollment.map(({ path, Component }, index) =>
                            <Route key={index} path={path} element={<Component imageData={imageData} showHeader={showHeader} />} exact={true} />)}
                        {AccountRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                        {BrandRoute.map((props, index) =>
                            <Route key={index} {...props} getUserDataFunction={getUserDataFunction} exact={true} />)}
                        {StoreRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                        {BillingRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                        {FFCRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                        {ProductRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                        {OrdersRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                        {BoImageRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                        {DashboardRoute.map((props, index) =>
                            <Route key={index} {...props} exact={true} />)}
                    </Routes>
                </Suspense>
                {(showMaster)
                    ?
                    <>
                        <BrandFooter logo={imageData.footer_logo} />
                    </>
                    : ''
                }
            </div>
        </BrowserRouter>
    );
}

export default App;