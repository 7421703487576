import Steps from './steps'

export default function Four(props){

    return (
        <div>
           <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections">
			            	<h3>Get your stores ready</h3>
			            				            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="stepsimg">
			            					<i className="fa-light fa-store"></i>
			            					<h3>3</h3>
			            					<h4>Enroll your stores</h4>
			            				</div>
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>Enroll all your stores under the created account so they can be visible to all Brands you work with.
			            					</p>

											<p>As soon as your stores are enrolled you can affiliate them to Brands as fulfillment centers.
											</p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	<Steps step={4} {...props} />
		            	</div>
		            </div>
	            </div>
            </div>
        </div>
     </div>
    );
};