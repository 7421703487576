import React from "react";
import { Link } from "react-router-dom";
import AccountOff from './../../assets/images/accountoff.png';

function BrandAside(props) {
	return (
		<>
			<aside className="main-sidebar pre-enroll">
				<div className="user-profile-height stager">
					<div className="custom-logo withoutlogo">
						<div className="logoname">
							<span className="maincompany">Company Logo</span>
							<small>Max 200x90px 100kb</small>
						</div>
						<div className="itemsas">Company Name</div>
					</div>
					<div className="itemscenter">
						<ul>
							<li className="gogrn">Current status: <span className="orng">0% Complete</span></li>
							<li className="notactivated">Billing plan: <span className="orng">-</span></li>
							<li className="notactivated">Group plan: <span className="orng">-</span></li>
							<li>Active stores: <span className="orng">0</span></li>
							<li>Active FFcenters: <span className="orng">0</span></li>
						</ul>
					</div>
				</div>
				<div className="menusections">
					<div className="navitems-area">
						<ul>
							<li className="MenuIsoff">
								<Link to="#" onClick={(e) => e.preventDefault()}>
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="menuison" className="menuison" src={AccountOff} />
										</div>
										Account
									</div>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</aside>
		</>
	);
}
export default BrandAside;