import { lazy } from 'react';
import LoginFunc from '../utils/updateDataFunc';
import Logo from './../assets/images/logologin.png';
const PrivateRoute = lazy(() => import('../utils/privateRoute'));
const OrdersList = lazy(() => import('../components/orders/orderList'));

const imageData = {
    'logo': Logo,
    'footer_logo': Logo
};

const route = [
    { path: '/orders/list', element: <PrivateRoute><OrdersList loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
];
 
export default route;