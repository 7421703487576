import axios from 'axios';

const addDataIntoCache = (cacheName, url, response) => {
    const data = new Response(JSON.stringify(response));
    if ('caches' in window) {
        caches.open(cacheName).then((cache) => {
            cache.put(url, data);
        });
    }
};

const loginFun = (d, statusPercentage) => {
    const formData = {
        'userID': d,
        'status': statusPercentage
    };
    if (parseInt(localStorage.getItem('status')) <= parseInt(statusPercentage) || statusPercentage === 'Completed') {
        localStorage.setItem('status', statusPercentage);
        axios.post(process.env.REACT_APP_API_URL + 'updateUserStatus?code=' + process.env.REACT_APP_API_CODE, formData).then(res => {
            axios.post(process.env.REACT_APP_API_URL + 'get-user-data?code=' + process.env.REACT_APP_API_CODE, { 'id': d }).then((res) => {
                if (res.data.status === 200) {
                    const resData = {
                        'status': res.data.data?.status,
                        'completePercentage': res.data.data?.completePercentage,
                        'AccountMenu': res.data.data?.AccountMenu,
                        'DashboardMenu': res.data.data?.DashboardMenu,
                        'BOimagesMenu': res.data.data?.BOimagesMenu,
                        'FullfillmentCenterMenu': res.data.data?.FullfillmentCenterMenu,
                        'StoresMenu': res.data.data?.StoresMenu,
                        'RetailersMenu': res.data.data?.RetailersMenu,
                        'ProductsMenu': res.data.data?.ProductsMenu,
                        'OrdersMenu': res.data.data?.OrdersMenu,
                        'BrandsMenu': res.data.data?.BrandsMenu,
                        'PromotersMenu': res.data.data?.PromotersMenu,
                        'companyName': (typeof res.data.data?.Companies != 'undefined') ? res.data.data?.Companies[0]?.companyName : '',
                        'companyLogo': res.data.data?.companyDetail?.logoURL,
                        'billingPlan': res.data.data?.companyDetail?.billingPlan,
                        'BillingMenu': res.data.data?.BillingMenu
                    };
                    localStorage.setItem('__data', JSON.stringify(resData));
                    addDataIntoCache('MyCache', window.location.origin, res.data.data);

                    
                    if (!localStorage.getItem('currentRetailBrandID') && !localStorage.getItem('currentRetailBrandName')) {
                        if (res.data.data?.allUserData?.Company?._retailBrands?.length) {
                            const currentRetailer = res.data.data?.allUserData?.Company?._retailBrands.find(item => item.ourID === res?.data?.data?.activeBrand?.retailBrandID);
    
                            if (currentRetailer) {
                                localStorage.setItem('currentRetailBrandID', currentRetailer._id);
                                localStorage.setItem('currentRetailBrandName', currentRetailer.name);
                            }
                        }
                    }
    
                    if (!localStorage.getItem('currentCompanyID')) {
                        const currentCompanyID = res.data.data?.companyDetail?._id;
                        const currentCompanyName = res.data.data?.companyDetail?.name !== undefined
                            ? res.data.data?.companyDetail?.name : '-';
    
                        localStorage.setItem('currentCompanyID', currentCompanyID);
                        localStorage.setItem('currentCompanyName', currentCompanyName);
                    }
                }
            });
        });
    } else {
        axios.post(process.env.REACT_APP_API_URL + 'get-user-data?code=' + process.env.REACT_APP_API_CODE, { 'id': d }).then((res) => {
            if (res.data.status === 200) {
                const resData = {
                    'status': res.data.data?.status,
                    'completePercentage': res.data.data?.completePercentage,
                    'AccountMenu': res.data.data?.AccountMenu,
                    'DashboardMenu': res.data.data?.DashboardMenu,
                    'BOimagesMenu': res.data.data?.BOimagesMenu,
                    'FullfillmentCenterMenu': res.data.data?.FullfillmentCenterMenu,
                    'StoresMenu': res.data.data?.StoresMenu,
                    'RetailersMenu': res.data.data?.RetailersMenu,
                    'ProductsMenu': res.data.data?.ProductsMenu,
                    'OrdersMenu': res.data.data?.OrdersMenu,
                    'BrandsMenu': res.data.data?.BrandsMenu,
                    'PromotersMenu': res.data.data?.PromotersMenu,
                    'companyName': (typeof res.data.data?.Companies != 'undefined') ? res.data.data?.Companies[0]?.companyName : '',
                    'companyLogo': res.data.data?.companyDetail?.logoURL,
                    'BillingMenu': res.data.data?.BillingMenu,
                    'billingPlan': res.data.data?.companyDetail?.billingPlan
                };
                localStorage.setItem('__data', JSON.stringify(resData));
                addDataIntoCache('MyCache', window.location.origin, res.data.data);

                if (!localStorage.getItem('currentRetailBrandID') && !localStorage.getItem('currentRetailBrandName')) {
                    if (res.data.data?.allUserData?.Company?._retailBrands?.length) {
                        const currentRetailer = res.data.data?.allUserData?.Company?._retailBrands.find(item => item.ourID === res?.data?.data?.activeBrand?.retailBrandID);

                        if (currentRetailer) {
                            localStorage.setItem('currentRetailBrandID', currentRetailer._id);
                            localStorage.setItem('currentRetailBrandName', currentRetailer.name);
                        }
                    }
                }

                if (!localStorage.getItem('currentCompanyID')) {
                    const currentCompanyID = res.data.data?.companyDetail?._id;
                    const currentCompanyName = res.data.data?.companyDetail?.name !== undefined
                        ? res.data.data?.companyDetail?.name : '-';

                    localStorage.setItem('currentCompanyID', currentCompanyID);
                    localStorage.setItem('currentCompanyName', currentCompanyName);
                }
            }
        });
    }
};
export default loginFun;