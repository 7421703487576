import { lazy } from "react";
import Logo from "./../assets/images/logologin.png";
import FooterLogo from "./../assets/images/footer-logologin.png";
import LoginFunc from './../utils/updateDataFunc';
const PrivateRoute = lazy(() => import('./../utils/privateRoute'));
const BillingWelcome = lazy(() => import('./../components/billing/welcome'));
//const BillingPlans = lazy(() => import('./../components/billing/billingPlans'));
const BillingPlans = lazy(() => import('./../components/billing/index'));
const SelectedPlans = lazy(() => import('./../components/billing/selectedPlans'));
const MenuToExplore = lazy(() => import('./../components/billing/menuToExplore'));
const InvoiceList = lazy(() => import('./../components/billing/invoiceList'));

const imageData = {
    "logo": Logo,
    "footer_logo": FooterLogo
};

const route = [
    {
        path: '/billing/welcome',
        element: <PrivateRoute><BillingWelcome loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
    },
    {
        path: '/billing',
        element: <PrivateRoute><BillingPlans loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
    },
    {
        path: '/billing-plan',
        element: <PrivateRoute><BillingPlans loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
    },
    {
        path: '/billing/selected-plan',
        element: <PrivateRoute><SelectedPlans loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
    },
    {
        path: '/billing-plan/invoices',
        element: <PrivateRoute><InvoiceList loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
    },
    {
        path: '/menu-to-explore',
        element: <PrivateRoute><MenuToExplore loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>
    }
]

export default route;