import Steps from './steps'

export default function Third(props){
	
    return (
        <div>
           <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections">
			            	<h3>Register your Stores' name and logo</h3>
			            				            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="stepsimg">
			            					<i className="fa-light fa-font-awesome"></i>
			            					<h3>2</h3>
			            					<h4>Enroll a Retail Brand</h4>
			            				</div>
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>Enroll your Retail Brand by adding the name and logo of your stores so shoppers can identify them easier.
			            					</p>

											<p className="lilsmalltext">If your Retail Brand (stores' name and logo) is also a Label Brand (you sell products labeled with the same name and logo as your stores) you also need to create an account in BOstageB (click here).
											</p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	<Steps step={3} {...props} />
		            	</div>
		            </div>
	            </div>
            </div>
        </div>
     </div>
    );
};